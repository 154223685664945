<template>
  <div
    class="patient-info"
    @click="onClick"
  >
    <div class="patient-info-status">
      <img
        v-if="typeOfAppeal !== null"
        :src="PROJECT_TYPES[typeOfAppeal]?.logo"
        alt="#"
        class="patient-info__project-type-img"
      >
    </div>
    <span class="name-short-block">
      {{ patientFio(patient) }}
    </span>
    <div
      v-if="takeOfWork && getUnassignedAppealTimer && isMounted"
      class="patient-info-work ml-auto"
      :class="statusColor(getUnassignedAppealTimer)"
    >
      {{ unassignedAppealFormattedTime }}
    </div>
    <div
      v-if="takeOfWork && getUnassignedAppealTimer"
      class="patient-info-work ml-2"
      :class="statusColor(getUnassignedAppealTimer)"
      @click="onClickTakeOn"
    >
      {{ comparisonAppealReturnedToLine? 'Перевод': 'В работу' }}
    </div>
    <div
      v-if="!takeOfWork && getAppealTimer !==null && isMounted"
      class="patient-info-work ml-auto"
      :class="statusColor(getAppealTimer)"
    >
      {{ appealFormattedTime }}
    </div>
    <div
      v-if="numberOfMessages > 0 && isMounted"
      class="chat-number-calls"
    >
      {{ numberOfMessages }}
    </div>
  </div>
</template>
<script>
import { PROJECT_TYPES } from '@/helpers/consts';
import {
  differenceInSeconds,
} from '@evd3v/date-fns';
import { utc0ToDateWithUserTimezone } from '@/helpers/utils';

export default {
  name: 'ChatPatientInfoItem',
  props: {
    patient: {
      type: Object,
      required: true,
    },
    takeOfWork: {
      type: Boolean,
      default: false,
    },
    isPatientLast: {
      type: Boolean,
      default: false,
    },
    activePatient: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentTime: null,
      timer: null,
      PROJECT_TYPES,
      isMounted: false,
    };
  },
  computed: {
    isActivePatient() {
      return this.activePatient === this.patient?.id;
    },
    typeOfAppeal() {
      switch (this.patient?.personInfo?.projectType) {
        case 'Doctis':
          return 0;
        case 'RTHealth':
          return 1;
        default:
          return null;
      }
    },
    comparisonAppealReturnedToLine() {
      return this.patient.chatAppealStatus.includes('ReturnedToLine');
    },
    getUnassignedAppealTimer() {
      if (this.takeOfWork) {
        const previousDate = new Date(this.patient.createdAt);
        return differenceInSeconds(this.currentTime, utc0ToDateWithUserTimezone(previousDate));
      } return null;
    },
    getAppealTimer() {
      if (!this.takeOfWork && this.isPatientLast) {
        const previousDate = new Date(this.patient.lastPersonMessage);
        return differenceInSeconds(this.currentTime, utc0ToDateWithUserTimezone(previousDate));
      } return null;
    },
    appealFormattedTime() {
      if (this.getAppealTimer <= 300) return this.timeFormat(300 - this.getAppealTimer);
      return this.timeFormat(0);
    },
    unassignedAppealFormattedTime() {
      if (this.getUnassignedAppealTimer <= 300) return this.timeFormat(300 - this.getUnassignedAppealTimer);
      return this.timeFormat(0);
    },
    numberOfMessages() {
      return this.patient.unreadAmount;
    },
  },
  watch: {
    getUnassignedAppealTimer() {
      if (this.takeOfWork) {
        if (this.getUnassignedAppealTimer / 60 >= 5) {
          this.clearTimer();
        }
      }
    },
    getAppealTimer() {
      if (this.isPatientLast) {
        if (this.timer !== null) {
          if (this.getAppealTimer / 60 >= 5) {
            this.clearTimer();
          }
        } else {
          this.updateTime();
          this.setTimer();
        }
      }
    },
    isPatientLast() {
      if (!this.isPatientLast) {
        this.clearTimer();
      }
    },
  },
  async mounted() {
    await this.setTimer();
    setTimeout(() => {
      this.isMounted = true;
    }, 1000);
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    setTimer() {
      this.timer = setInterval(this.updateTime, 1000);
    },
    timeFormat(s) {
      const secMin = (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s;
      return secMin;
    },
    statusColor(timer) {
      if (timer / 60 >= 0 && timer / 60 < 2) return 'success-color';
      if (timer / 60 >= 2 && timer / 60 < 4) return 'returned-color';
      return 'alarm-color';
    },
    updateTime() {
      this.currentTime = new Date(new Date().getTime());
    },
    patientFio(patient) {
      let { lastName, firstName, middleName } = patient.personInfo;
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
      firstName = `${firstName.charAt(0).toUpperCase()}.`;
      if (middleName !== null) { middleName = `${middleName.charAt(0).toUpperCase()}.`; }
      return `${lastName} ${firstName} ${middleName || ''}`;
    },
    onClick(e) {
      this.$emit('click', e);
    },
    onClickTakeOn(e) {
      this.$emit('take-on', e);
    },
  },
};
</script>
<style lang="scss" scoped>
  .patient-info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 10px;
    margin: 0 5px;
    border-bottom: 1px solid rgb(228, 225, 225);
    &-status {
      display: flex;
      align-items: center;
      min-width: 25px;
    }
    &__project-type-img{
      height: 17px;
      width: 17px;
      border-radius: 4px;
    }
    span {
      font-weight: 600;
      color: #7c7575;
    }
    &-name{
      font-size: 16px;
      font-weight: 400;
      color: #c2bebe;
      margin: 10px 0;
      word-break:break-all;
    }

    &-work {
      width: 25%;
      font-size: 12px;
      text-align: center;
      border-radius: 5px;
      color:white;
      padding: 0 5px;
      right: 10%;
      top: 10px;
    }

    .info-count-wrapper {
      flex-shrink: 0;
      width: 40px;
    }
    .info-count{
      font-size: 12px;
      padding: 0 5px;
      background-color: #007ef5;
      border-radius: 40%;
      color:white;
      margin-left: auto;
      width: min-content;
    }
  }
  .returned-color{
    background-color: rgb(255, 153, 0);
  }
  .success-color{
    background-color: #108a03;
  }
  .alarm-color{
    background-color: rgb(239 68 68);
  }
  .chat-number-calls {
    display: flex;
    background-color: rgb(239 68 68);
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    width: 24px;
    line-height: 24px;
    justify-content: center;
    margin-left: 7px;
  }
  .name-short-block {
    width: auto;
    max-width: 160px;
    hyphens: auto;
    margin-right: 5px;
    word-break: break-word;
  }
</style>
