<template>
  <div
    ref="textarea"
    class="resizable-textarea"
    :placeholder="placeholder"
    :contenteditable="allowEditing"
    @input="onInput"
    @keydown.enter.prevent="sendMessage"
  />
</template>

<script>
export default {
  name: 'ResizableTextarea',
  props: {
    protocol: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Написать сообщение...',
    },
    allowEditing: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$refs.textarea.value = '';
  },
  methods: {
    sendMessage(e) {
      if (e.shiftKey) {
        const { textarea } = this.$refs;
        const browser = navigator.userAgent;
        const isFirefox = browser?.search(/Firefox/) > 0;

        let shift = '\n\n';
        if (isFirefox && textarea.innerText.includes('\n')) shift = '\n';

        const el = this.$refs.textarea;
        el.innerText += shift;

        const range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        return;
      }

      return this.$emit('messageSend', this.messageSend);
    },
    onInput(e) {
      this.$emit('inputText', e.target.innerText);
    },
  },
};
</script>

<style lang="scss" scoped>
.resizable-textarea {
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  padding: 10px 50px 5px 25px;
  color: #2c2c2c;
  border: 1px solid #e5e5e5;
  border-radius: 5px 0 0 5px;
  max-height: 120px;
  min-height: 40px;
  max-width: 100%;
  outline: none !important;
  overflow: auto;
  overflow-x: hidden;
  resize: none;
  white-space: pre-wrap; /* Сохраняет пробелы и переносы строк */
  overflow-wrap: break-word; /* Перенос длинных слов */
  display: block;
}

.resizable-textarea::-webkit-scrollbar {
  width: 6px;
  border-radius: 17px;
}

.resizable-textarea::-webkit-scrollbar-track {
  border-radius: 17px;
  background: #e4f3ff;
}

.resizable-textarea::-webkit-scrollbar-thumb {
  background: #9ab6ea;
  border-radius: 17px;
}
</style>
