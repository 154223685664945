<template>
  <div class="patient-tabs-col">
    <div
      v-for="tab in filteredPatientTabs"
      :key="`patient-${tab.index}`"
    >
      <div class="d-flex justify-content-end">
        <div class="d-flex w-100 mt-2">
          <div
            class="patient-tab-item patient-tab-order"
            @click="tab.onClick"
          >
            {{ tab.text }}
            <div
              v-if="tab.text === 'Страховые полисы' && hasExpiringPolicies"
              class="red-round-circle ml-2"
            />
          </div>
          <b-button
            v-if="tab.text === 'Обращения'"
            v-b-tooltip.hover.bottomleft.v-light
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="appeal-btn ml-2"
            :disabled="!patientDetailInfo?.id"
            title="Создать обращение"
            @click="openAppealEditor"
          >
            <b-icon
              class="icon"
              icon="plus"
            />
          </b-button>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="patientDetailInfo?.id && patientDetailInfo?.comment"
        class="mt-2"
      >
        <transition name="fade">
          <div
            v-if="isInfoShow"
            class="p-3"
          >
            <div class="text-comment">
              {{ patientDetailInfo.comment }}
            </div>
          </div>
        </transition>

        <div
          style="cursor: pointer;"
          class="comment-style p-2"
          @click="isInfoShow = !isInfoShow"
        >
          <div>Комментарий</div>
          <b-icon
            class="ml-2"
            icon="caret-down-fill"
            variant="secondary"
            :rotate="isInfoShow ? 180 : 0"
          />
        </div>
      </div>
    </transition>
    <div class="block-divider" />

    <div class="mb-3">
      <div
        v-for="tab in filteredTabs"
        :key="`tab-${tab.index}`"
      >
        <div class="d-flex justify-content-end">
          <div
            class="patient-tab-item mt-2"
            @click="tab.onClick"
          >
            {{ tab.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixinRoles } from '@/mixins';
import { FEATURES_FOR_CHAT } from '@/helpers/consts';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

import { showCustomMessage } from '@/helpers/messages';

export default {
  name: 'ChatPatientDetailTabs',
  mixins: [mixinRoles],
  props: {
    patientDetailInfo: {
      type: Object,
      default: () => ({}),
    },
    patientInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isInfoShow: false,
      patientTabs: [
        {
          index: 0,
          text: 'Информация о пациенте',
          showTab: true,
          onClick: this.openPatientInfoModal,
        },
        {
          index: 1,
          text: 'Консультации',
          showTab: true,
          onClick: this.openPatientConsultationModal,
        },
        {
          index: 2,
          text: 'Страховые полисы',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.insurancePolicies),
          onClick: this.openPatientPoliciesModal,
        },
        {
          index: 3,
          text: 'Обращения',
          showTab: true,
          onClick: this.openPatientAppealsModal,
        },
        {
          index: 4,
          text: 'Запись пациента в очередь',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.registerPatientInTheQueue),
          onClick: this.openPatientAppointmentModal,
        },
        {
          index: 5,
          text: 'Протоколы',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.btnProtocolInChat),
          onClick: this.openProtocolsModal,
        },
      ],
      tabs: [
        {
          index: 0,
          text: 'Лабораторные отделения',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.labDepartments),
          onClick: this.openLaboratoryBranchesModal,
        },
        {
          index: 1,
          text: 'Список клиник',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.clinicList),
          onClick: this.openClinicsModal,
        },
        {
          index: 2,
          text: 'Врачи',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.doctors),
          onClick: this.openDoctorsModal,
        },
      ],
    };
  },
  computed: {
    filteredPatientTabs() {
      return this.patientTabs.filter((tab) => tab.showTab);
    },
    filteredTabs() {
      return this.tabs.filter((tab) => tab.showTab);
    },
    hasExpiringPolicies() {
      if (!this.patientDetailInfo || !this.patientDetailInfo.policies) {
        return false;
      }
      const twoMonthsAhead = new Date();
      twoMonthsAhead.setMonth(twoMonthsAhead.getMonth() + 2);
      return this.patientDetailInfo.policies.some((policy) => {
        if (!policy.isActivated) {
          return false;
        }
        const parseEndDate = new Date(policy.endDate);
        return parseEndDate < twoMonthsAhead;
      });
    },
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: key?.name, url: key?.url });
    },
    openAppealEditor(index) {
      console.log(index);
      uiService.showModal(MODALS.APPEAL_EDITOR_NEW_MODAL, {
        name: 'AppealEditorModalNew',
        props: {
          personId: this.patientDetailInfo.id,
          creatingFromChat: true,
          chatAppealId: this.patientInfo.id,
        },
      });
    },
    openPatientInfoModal() {
      uiService.showModal(MODALS.PATIENT_DETAIL_CHAT_MODAL, {
        name: 'PatientDetailChatModal',
        props: {
          patientId: this.patientDetailInfo.id,
          modalName: 0,
        },
      });
    },
    openPatientConsultationModal() {
      uiService.showModal(MODALS.PATIENT_DETAIL_CHAT_MODAL, {
        name: 'PatientDetailChatModal',
        props: {
          patientId: this.patientDetailInfo.id,
          modalName: 1,
        },
      });
    },
    openPatientPoliciesModal() {
      uiService.showModal(MODALS.PATIENT_DETAIL_CHAT_MODAL, {
        name: 'PatientDetailChatModal',
        props: {
          patientId: this.patientDetailInfo.id,
          patientInfo: this.patientDetailInfo,
          modalName: 2,
        },
      });
    },
    openPatientAppealsModal() {
      uiService.showModal(MODALS.PATIENT_DETAIL_CHAT_MODAL, {
        name: 'PatientDetailChatModal',
        props: {
          patientId: this.patientDetailInfo.id,
          patient: this.patientDetailInfo,
          modalName: 3,
        },
      });
    },
    openProtocolsModal() {
      if (this.patientDetailInfo !== null) {
        uiService.showModal(MODALS.PROTOCOLS_MODAL, {
          name: 'ProtocolsModal',
          props: {
            personId: this.patientDetailInfo.id,
          },
        });
      } else {
        showCustomMessage('warning', 'Ошибка', 'Выберите пациента для просмотра протоколов');
      }
    },
    openPatientAppointmentModal() {
      if (this.patientDetailInfo !== null) {
        uiService.showModal(MODALS.PATIENT_APPOINTMENT_MODAL, {
          name: 'PatientAppointmentModal',
          props: {
            patientDetails: this.patientDetailInfo,
          },
        });
      } else {
        showCustomMessage('warning', 'Ошибка', 'Выберите пациента для записи в очередь');
      }
    },
    openLaboratoryBranchesModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'LaboratoryBranchesModal',
        props: {
        },
      });
    },
    openClinicsModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ClinicsModal',
        props: {
        },
      });
    },
    openDoctorsModal() {
      uiService.showModal(MODALS.DOCTORS_MODAL, {
        name: 'DoctorsModal',
        props: {
          patientId: this.patientDetailInfo.id,
          chatAppealId: this.patientInfo.id,
          modalName: 'DoctorsModal',
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .patient-tabs-col {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      border-radius: 5px;

    .patient-tab-item {
      flex-grow: 1;
      text-align: center;
      background-color: #ebebeb;
      border-radius: 3px;
      box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
      padding: 5px 0;
      cursor: pointer;
    }
  }
  .block-divider {
    display: block;
    height: 1px;
    width: 95%;
    margin: 15px auto 10px;
    background: rgb(199, 199, 199);
  }

.red-round-circle {
  position: relative;
  text-align: center;
  background: red;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  animation: pulse 2.5s linear infinite;
}

.comment-style {
  background-color: #EBEBEB;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-comment {
  font-size: 12px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  display: block;
}

.patient-tab-order {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all .7s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
